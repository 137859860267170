<template>
  <section class="page-content">
    <div class="category-title">
        <h1>{{ category.name }}</h1>
    </div>
    <div class="category-image-link" v-if="category.children_count == 0 && category.brand == null">
        <b-link class="category-link" :to="`/` + category.url_path">
            <div class="category-box">
              <div class="banner-img">
                <b-img-lazy
                  v-if="category.image != null"
                  :src="category.image"
                  :alt="category.name"
                  blank-height="150"
                  fluid
                ></b-img-lazy>
              </div>

            </div>
          </b-link>
    </div>
    <div class="category-description" v-html="category.description">
    </div>
    <CategoryPageContentFilter />
    <FilteredProducts />
    <CategoryPageContentFilter />
  </section>
</template>

<script>
import CategoryPageContentFilter from "@/base/core/components/CategoryPageContentFilter";
import FilteredProducts from "@/esf_kerkrade_vitanatura/core/components/FilteredProducts";
export default {
  name: "CategoryPageContent",
  props: {
      category: Object,
  },
  components: {
    CategoryPageContentFilter,
    FilteredProducts,
  },
};
</script>


