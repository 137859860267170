<template>
    <div class="row">
      <div
        class="col-6 mb-md-30 col-md-4"
        v-for="product of productsList"
        :key="product.id"
        v-remember-scroll-cache="{ itemsName: 'products', selector: 'a' }"
      >
        <ProductCard :product="product" :optionHeight="getOptionHeight" />
      </div>
    </div>
  </template>
  
  <script>
  import ProductCard from "@/esf_kerkrade_vitanatura/core/components/ProductCard";
  export default {
    name: "ProductsSection",
    components: { ProductCard },
    props: {
      productsList: {
        type: Array,
        require: true,
      },
    },
    computed: {
      getOptionHeight() {
        this.productsList.forEach((p) => {
          if (p.configurable_options) {
            p.configurable_options.find((co) => {
              if (co.values) {
                if (co.values.length > this.maxCount) {
                  this.maxCount = co.values.length;
                }
              }
            });
          }
        });
        let returnValue = Math.floor(this.maxCount / 4, 0) + 1;
        return returnValue;
      },
    },
    data() {
      return {
        maxCount: 0,
      };
    },
  };
  </script>
  