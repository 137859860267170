<template>
    <AppLayout wrapperClass="productpage">
        <Breadcrumbs />
        <div class="d-flex p-30">
            <div class="product-category-sidebar">
            </div>
            <div class="product-category-list pl-30 w-100" >
                <div class="category">
                    <CategoryOverview v-if="category.is_anchor == 0" :category="category" :categories="categories" />

                    <div class="container" v-if="category.is_anchor == 1">
                        <div class="row">
                            <ProductFilter class="col-lg-3" />
                            <ProductsListingV2 class="col-lg-9" />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </AppLayout>
</template>

<script>
import config from "@config";
import AppLayout from "@/esf_kerkrade_vitanatura/core/components/AppLayout.vue";

import Breadcrumbs from "@/base/core/components/Breadcrumbs";
import ProductFilter from "@/esf_kerkrade_vitanatura/core/components/ProductFilter";
import CategoryOverview from "@/esf_kerkrade_vitanatura/core/components/CategoryOverview";
import ProductsListingV2 from "@/esf_kerkrade_vitanatura/core/components/ProductListingV2";
export default {

    components: {
        AppLayout,
        Breadcrumbs,
        ProductFilter,
        ProductsListingV2,
        CategoryOverview,
    }, 
    computed: {
        category() {
            return this.$store.getters["category/getCurrentCategory"];
        },
        categoryId() {
            return this.$store.getters["category/getCurrentCategoryId"];
        },
        categories() {
            return this.category.children;
        },
        categoryForm() {
            return this.$store.getters["forms/getCategoryForm"];
        },
        gtmProducts() {
            return this.$store.getters["category/getGtmProducts"];
        },
    },
    watch: {
        $route(to, from) {
            if (to.path !== from.path) {
                const query = {};
                const pageSize = config.products && config.products.perPage;
                const perPage = this.$store.getters["category/getProductsPerPage"];
                if (perPage != pageSize) query["page-size"] = perPage;
                this.$router.push({ path: to.path, query: query });
            }
        },
        gtmProducts() {
            if (this.$gtm.enabled()) {
                if (this.gtmProducts.length > 0) {
                    window.dataLayer.push({
                        event: "eec.impressionView",
                        ecommerce: {
                            impressions: this.gtmProducts,
                        },
                    });
                }
            }
        }, 
        destroyed() {
            this.$store.commit("category/setLoadProducts", false);
        },
        methods: {},
        metaInfo() {
            return {
                meta: [
                    { charset: "utf-8" },
                    { name: "viewport", content: "width=device-width, initial-scale=1" },
                    {
                        name: "title",
                        content: this.category,
                    },
                    {
                        name: "keywords",
                        content: this.category ? this.category.meta_keywords : "",
                    },
                    {
                        name: "description",
                        content: this.category ? this.category.meta_description : "",
                    },
                ],
                title: this.category.name,
            };
        },
    },
};
</script>
